import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import Seo from "components/Seo"
import Author from "components/consultants/Author"

const CaseSingleTemplate = ({ data: { previous, next, post } }) => {
  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }

  return (
    <div className="">
      <Seo title={post.title} description={post.excerpt} />
      <article itemScope itemType="http://schema.org/Article">
        <header className="py-8 lg:py-16">
          <div className="w-full lg:container mx-auto px-4 lg:px-8">
            <div className="flex flex-row flex-wrap">
              <div className="w-full lg:w-2/12">
                <h2 className="text-primary mb-3 lg:text-lg"></h2>
              </div>
              <div className="w-full lg:w-8/12">
                <h1
                  itemProp="headline"
                  className="text-2xl lg:text-3xl xl:text-4xl font-medium"
                >
                  {parse(post.title)}
                </h1>
                <ul className="mt-8 lg:mt-12 flex flex-row flex-wrap -mx-4 lg:-mx-8">
                  {post?.case?.consulting.type && (
                    <li className="w-1/3 sm:w-auto px-4 lg:px-8">
                      <h4 className="text-gray-500 mb-1 text-sm">업종</h4>
                      <p className="text-gray-900 lg:text-lg">
                        {post.case.consulting.type}
                      </p>
                    </li>
                  )}
                  {post?.case?.consulting?.sales && (
                    <li className="w-1/3 sm:w-auto px-4 lg:px-8">
                      <h4 className="text-gray-500 mb-1 text-sm">매출액</h4>
                      <p className="text-gray-900 lg:text-lg">
                        {post.case.consulting.sales}
                      </p>
                    </li>
                  )}
                  {post?.case?.consulting?.workers && (
                    <li className="w-1/3 sm:w-auto px-4 lg:px-8">
                      <h4 className="text-gray-500 mb-1 text-sm">근로자 수</h4>
                      <p className="text-gray-900 lg:text-lg">
                        {post.case.consulting.workers}
                      </p>
                    </li>
                  )}
                  {post?.case?.consulting?.age && (
                    <li className="w-1/3 sm:w-auto px-4 lg:px-8">
                      <h4 className="text-gray-500 mb-1 text-sm">설립연도</h4>
                      <p className="text-gray-900 lg:text-lg">
                        {post.case.consulting.age}
                      </p>
                    </li>
                  )}
                </ul>

                <div className="mt-8 lg:mt-12">
                  {post?.case?.consultant &&
                    post.case.consultant.length > 0 &&
                    post.case.consultant.map(item => {
                      return (
                        <Author
                          key={item.id}
                          name={item.title}
                          title={item?.consultant?.role}
                          avatar={
                            <GatsbyImage
                              className="mr-3 w-14 h-14 bg-gray-200 rounded-full border border-gray-100"
                              image={
                                item.featuredImage?.node?.localFile
                                  ?.childImageSharp?.gatsbyImageData
                              }
                              alt={item.title}
                            />
                          }
                        />
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="relative overflow-hidden bg-gray-100">
          <div style={{ paddingBottom: "380px" }}></div>
          <GatsbyImage
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              inset: 0,
              objectFit: "cover",
            }}
            image={featuredImage.data}
            alt={featuredImage.alt}
          />
          <div></div>
        </div>

        <div className="w-full lg:container px-4 lg:px-8 mx-auto">
          <div className="pb-8 lg:pb-16">
            <div className="w-full lg:w-8/12 mx-auto">
              <div className="relative z-20 bg-white -mt-24 mb-8 lg:mb-16 shadow-md p-4 lg:p-8 border border-gray-100">
                {post?.excerpt && (
                  <div className="mb-4 lg:mb-8 last:mb-0">
                    <h3 className="text-lg lg:text-xl font-medium mb-1 lg:mb-3">
                      요약
                    </h3>
                    <p className="text-gray-500 lg:text-lg break-words">
                      {parse(post?.excerpt)}
                    </p>
                  </div>
                )}
                {post?.case?.consultant?.goal && (
                  <div className="mb-4 lg:mb-8 last:mb-0">
                    <h3 className="text-lg lg:text-xl font-medium mb-1 lg:mb-3">
                      솔루션
                    </h3>
                    <p className="text-gray-500 lg:text-lg break-words">
                      {parse(post?.case?.consulting?.goal)}
                    </p>
                  </div>
                )}
                {post?.case?.consultant?.result && (
                  <div className="mb-4 lg:mb-8 last:mb-0">
                    <h3 className="text-lg lg:text-xl font-medium mb-1 lg:mb-3">
                      결과
                    </h3>
                    <p className="text-gray-500 lg:text-lg break-words">
                      {parse(post?.case?.consulting?.result)}
                    </p>
                  </div>
                )}
              </div>
              <div>
                {!!post.content && (
                  <section
                    itemProp="articleBody"
                    className="max-w-screen-lg prose lg:prose-lg"
                  >
                    {parse(post.content)}
                  </section>
                )}
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  )
}

export default CaseSingleTemplate

export const pageQuery = graphql`
  query BlogCaseById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpCase(id: { eq: $id }) {
      id
      title
      content
      excerpt
      date(formatString: "yyyy년 MM월 DD일", locale: "ko")
      case {
        consulting {
          age
          type
          goal
          sales
          workers
          result
        }
        consultant {
          ... on WpConsultant {
            id
            slug
            uri
            title
            consultant {
              role
            }
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    previous: wpCase(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpCase(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
